import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useNavigate} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet, usePatch} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {BallTriangle} from "react-loader-spinner";

const DeletedUsers = () => {

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [filterEmail, setFilterEmail] = useState('');
    const [filterId, setFilterId] = useState('');
    const [filterLogin, setFilterLogin] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const navigate = useNavigate();
    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();
    const patchU = usePatch();

    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)


        let endpoint = `admin/users?typeUser=delete&page=${page + 1}&limit=${limit}`;

        if (filterEmail !== '') {
            endpoint += `&email=${filterEmail}`;
        }

        if (filterLogin !== '') {
            endpoint += `&login=${filterLogin}`;
        }

        if (filterId !== '') {
            endpoint += `&id=${filterId}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.users);
                    setCount(resp.allCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setUsers([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() =>{
                setIsLoaded(false)
            });;
    };

    const loadUsersWithArgParams = (searchParams) => {
        setIsLoaded(true)
        setIsDataLoading(true);

        let endpoint = `admin/users?typeUser=delete&page=${page + 1}&limit=${limit}`;

        if (searchParams.email !== '') {
            endpoint += `&email=${searchParams.email}`;
        }

        if (searchParams.login !== '') {
            endpoint += `&login=${searchParams.login}`;
        }

        if (searchParams.id !== '') {
            endpoint += `&id=${searchParams.id}`;
        }


        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.users);
                    setCount(resp.allCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setUsers([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() =>{
                setIsLoaded(false)
            });;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterEmail = (event) => {
        setFilterEmail(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: '',
                id: filterId,
                login: filterLogin
            });
        }
    };

    const handleFilterId = (event) => {
        setFilterId(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: filterEmail,
                login: filterLogin,
                id: ''
            });
        }
    };

    const handleFilterLogin = (event) => {
        setFilterLogin(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                login: '',
                id: filterId,
                email: filterEmail
            });
        }
    }

    const handleFilter = () => {
        loadUsers();
    };


    const onRecover = (id) => {

        confirm({
            title: 'Восстановление',
            content: 'Вы уверены, что хотите восстановить пользователя?',
            onConfirm: () => {
                patchU(`admin/user/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadUsers();
                        }
                    })
                    .catch((e)=>{
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        loadUsers();
    }, [page, limit]);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }


    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <Box className="headerWrapper" sx={{ pb: 2 }}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate('/app/users')}>Пользователи</li>
                    <li>/</li>
                    <li>Удаленные</li>
                </ul>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1050}}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email"
                                                        margin="normal"
                                                        name="filterEmail"
                                                        onChange={handleFilterEmail}
                                                        type="text"
                                                        value={filterEmail}
                                                        variant="outlined"
                                                        style={{width: '30%'}}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Id"
                                                        margin="normal"
                                                        name="filterId"
                                                        onChange={handleFilterId}
                                                        type="text"
                                                        value={filterId}
                                                        variant="outlined"
                                                        style={{width: '30%'}}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Login"
                                                        margin="normal"
                                                        name="filterLogin"
                                                        onChange={handleFilterLogin}
                                                        type="text"
                                                        value={filterLogin}
                                                        variant="outlined"
                                                        style={{width: '30%'}}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilter}
                                                        sx={{mt: 2, mb: 1}}
                                                    >
                                                        Применить
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Аватар
                                                            </TableCell>
                                                            <TableCell>
                                                                Имя
                                                            </TableCell>
                                                            <TableCell>
                                                                Фамилия
                                                            </TableCell>
                                                            <TableCell>
                                                                Email
                                                            </TableCell>
                                                            <TableCell>
                                                                Login
                                                            </TableCell>
                                                            <TableCell>
                                                                Роль
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {users.map((user) => (
                                                            <TableRow hover key={user.id}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {user.id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                                                                        <Avatar
                                                                            src={
                                                                                user.avatar ?
                                                                                    `${user.avatar}`
                                                                                    :
                                                                                    ''
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.name || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.surname || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.email || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.login || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user?.role?.user_role_russia || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{display: 'flex'}}>

                                                                        <Box sx={{ display: 'flex' }}>
                                                                            <Link to={`/app/user/${user.id}`}>
                                                                                <Button color="primary" variant="contained">
                                                                                    Инфо.
                                                                                </Button>
                                                                            </Link>
                                                                            <Box sx={{ ml: 2 }}>
                                                                                <Link to={`/app/user/edit/${user.id}`}>
                                                                                    <Button color="primary"
                                                                                            variant="contained">
                                                                                        Редакт.
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>
                                                                            <Button sx={{ ml: 2 }} color="primary" variant="contained" onClick={() => onRecover(user.id)}>
                                                                                Восстановить
                                                                            </Button>
                                                                        </Box>

                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={<span>Кол-во строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default DeletedUsers;
