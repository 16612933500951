import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, CardHeader
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../../API/request';
import {BallTriangle} from "react-loader-spinner";
import '../../../styles/All.css'
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import moment from "moment";

const ReferralHistory = () => {

    const navigate = useNavigate();

    const {id} = useParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const getU = useGet();

    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `users/referralHistory?user_id=${id}&page=${page + 1}&limit=${limit}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.history);
                    setCount(resp.allCount || 0);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setUsers([]);
                setCount(0);
            })
            .finally(() => {
                setIsDataLoading(false);
                setIsLoaded(false)
            });
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li onClick={() => navigate(`/app/user/${id}`)}>Информация</li>
                    <li>/</li>
                    <li>История</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 400}}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Avatar
                                                            </TableCell>
                                                            <TableCell>
                                                                Login отправителя
                                                            </TableCell>
                                                            <TableCell>
                                                                CRYG
                                                            </TableCell>
                                                            <TableCell>
                                                                Date/Time
                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {users?.map((user) => (
                                                            <TableRow hover key={user.id}>
                                                                <TableCell>
                                                                    {user?.id || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                                                                        <Avatar
                                                                            src={user?.user_avatar ? `${user?.user_avatar}` : ''}
                                                                        />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user?.from_user_login || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user?.amount || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {moment(user?.date_create).format("HH:mm, DD.MM.YYYY ") || '---'}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Кол-во строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default ReferralHistory;
