import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate, useParams} from 'react-router-dom';
import {usePost} from "../../API/request";
import React, {useState} from "react";
import {BallTriangle} from "react-loader-spinner";

const UserUpBalance = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const postU = usePost();

    const [values, setValues] = useState({
        balance: ''
    });

    const [errors, setErrors] = useState({
        balance: false,
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.balance.trim() === '') {
            validComplete = false;
            formErrors.balance = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            postU(`admin/finance?user_id=${id}`, {
                amount: values.balance,
            })
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно пополнили баланс пользователя');
                        clearForm();
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Ошибка сервера');
                })
                .finally(() =>{

                });
        }
    };


    return (
        <>
            <Helmet>
                <title>Create new user</title>
            </Helmet>
            <Box className="headerWrapper" sx={{ pb: 2 }}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li>Пополнение</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title={`Пополнение баланса пользователя. Id: ${id}`}
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>

                                    <TextField
                                        fullWidth
                                        label="Сумма для пополнения"
                                        margin="normal"
                                        name="balance"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.balance}
                                        variant="outlined"
                                        error={errors.balance}
                                    />

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Пополнить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default UserUpBalance;
