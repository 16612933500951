import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    TextField
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {useGet, usePost, usePut} from "../../../API/request";
import {BallTriangle} from "react-loader-spinner";
import "../../../styles/Configs/style.css"

const ConfigsEdit = () => {

    const {name} = useParams();
    const navigate = useNavigate();

    const postU = usePost();
    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        name: '',
        value: '',
        img: '',
        isImage: 0
    });
    const [errors, setErrors] = useState({
        name: false,
        value: false,
        img: false
    });

    const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');

    const [isLoaded, setIsLoaded] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
    const [dataBeforeChange, setDataBeforeChange] = useState();
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.value === '') {
            validComplete = false;
            formErrors.value = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Ничего не изменилось');
            return;
        }
        if (validate()) {
            setSubmitDisabled(true);

            let data = new FormData();

            if (values.img){
                data.append("files", values.img);
            } else {
                data.append("value", values.value);
            }

            putU(`admin/setting?setting=${name}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно обновили данные');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Ошибка сервера');
                })
                .finally(() => {

                });
        }
    };
    const imgUploaded = (event) => {
        setNothingChanged(false);
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            img: event.target.files[0]
        });
    };


    useEffect(() => {
        setIsLoaded(true)

        getU(`admin/setting?setting=${name}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    const values = {
                        name: resp.data.setting.name_setting,
                        value: resp.data.setting.value,
                        isImage: resp.data.setting.is_image
                    }
                    setUploadedImg(resp.data.setting.value)
                    setValues(values)
                }
            })
            .catch(() => {
                showAlert('error', 'Данные не были загружены');
            })
            .finally(() => {
                setIsLoaded(false)
            });

    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit user</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Конфиги
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Конфиги</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование команды"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    {values.isImage ?
                                        <div className="itemWrapper">
                                            <div className="container">
                                                <input accept="xlsx/*" type="file" style={{display: 'none'}}
                                                       id={1}
                                                       onChange={(event) => imgUploaded(event, 1)}/>
                                                <label htmlFor={1}>
                                                    <img src={uploadedImg} className="itemImg"/>
                                                    <div className="middle"/>
                                                </label>
                                            </div>
                                            <div className="help-text">
                                                Доступны следующие расширения: .png .jpg .jpeg .gif
                                            </div>
                                        </div>
                                        :
                                        <TextField
                                            fullWidth
                                            label="Значение"
                                            margin="normal"
                                            name="value"
                                            onChange={handleChange}
                                            type="text"
                                            value={values.value}
                                            variant="outlined"
                                            error={errors.value}
                                        />
                                    }

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ConfigsEdit;
